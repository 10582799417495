/* ------------------- page level permissions -------------------------- */
export const ADVANCE_SEARCH_TAB_PERMISSION = 'submission.advsearchTab';
export const ADMIN_TAB_PERMISSION = 'submission.adminTab';
export const COLLABORATION_PERMISSION = 'ba.collaboration';

/* ------------------- component level permissions --------------------- */
// submission form permissions
export const SUBMISSION_FORM_INTERNAL_COMMENTS_PERMISSION = 'ba.form.internal_notes';
export const SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION = 'ba.form.routing';
export const SUBMISSION_FORM_SAMPLE_RECEIVED_DATE_PERMISSION = 'ba.form.sample_received_date';
export const SUBMISSION_FORM_OPT_APPROVAL_PERMISSION = 'ba.form.opt_approval';
export const SUBMISSION_FORM_CUSTOM_COMMENTS_PERMISSION = 'ba.form.custom_comments';
export const SUBMISSION_FORM_SUBMIT_NEXT_PERMISSION = 'ba.form.submit_next';
export const SUBMISSION_FORM_ADMIN_EDIT_PERMISSION = 'ba.form.admin_edit';
export const SUBMISSION_FORM_RESUBMIT_PERMISSION = 'ba.form.resubmit';
export const SUBMISSION_FORM_START_ANNUAL_SAMPLES_PERMISSION = 'ba.form.start_annual_samples';

export const SG_ACCESS_PERMISSION = 'sg.access';
