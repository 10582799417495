import { productLineReportService } from '@/api/requests/baProductLineReportRequests.js';

export default {
    namespaced: true,

    state () {
        return {
            productLineReport: null
        };
    },

    getters: {
        getProductLineReport (state) {
            return state.productLineReport;
        }
    },

    mutations: {
        setProductLineReport (state, report) {
            state.productLineReport = report;
        }
    },

    actions: {
        async productLineReport ({ commit }, { bodyPayload }) {
            const { data: { data } } = await productLineReportService(bodyPayload);
            commit('setProductLineReport', data);
            return data
        },
        async resetProductLineReport ({ commit }) {
          commit('setProductLineReport', null);
        },
    }
};
