<template>
  <teleport :to="teleportLocation">
    <transition-group
      tag="div"
      :class="popupBoxPosition"
      name="modal-transition"
      enter-from-class="transition-all duration-500 opacity-0 transform scale-50"
      enter-active-class="ease-in duration-300"
      enter-to-class="opacity-100 transform scale-100"
      move-class="transition-all duration-500 ease-in-out"
      leave-from-class="transition-all duration-500 opacity-100 transform scale-100"
      :leave-active-class="`ease-out duration-200 ${alertStack.length > 1 ? 'absolute' : ''}`"
      leave-to-class="opacity-0 transform scale-50"
    >
      <div
        v-for="(alert, aIndex) in alertStack"
        :key="'alert' + aIndex"
        class="inline-block"
      >
        <slot name="alertBox">
          <div
            :class="[
              popupBoxClasses,
              alert.type === 'default' ? defaultBoxClasses : '',
              alert.type === 'error' ? errorBoxClasses : '',
              alert.type === 'success' ? successBoxClasses : '',
              alert.type === 'warning' ? warningBoxClasses : ''
            ]"
          >
            <base-svg
              class="h-5 w-5 inline-block mr-2"
              :src="'icons/' + getIcon(alert.type)"
              :svg-attributes="{
                class: 'h-full w-full'
              }"
              tag="span"
            />
            <slot name="message">
              <div class="mr-4 text-sm">
                {{ alert.message }}
              </div>
            </slot>
            <div class="flex items-center ml-auto">
              <base-svg
                class="h-3.5 w-3.5 inline-block text-primary-gray hover:text-black cursor-pointer"
                src="icons/cross.svg"
                :svg-attributes="{
                  class: 'h-full w-full'
                }"
                tag="span"
                @click="removeAlert(aIndex)"
              />
            </div>
          </div>
        </slot>
      </div>
    </transition-group>
  </teleport>
</template>

<script>
import { computed, watch } from 'vue';
import BaseSvg from './BaseSvg.vue';

export default {
    name: 'BaseToast',

    components: {
        BaseSvg
    },

    props: {
        modelValue: {
            type: Array,
            default: () => [],
            description: 'popup box stack'
        },
        teleportLocation: {
            type: String,
            default: 'body',
            description: 'html element selector where toast should be teleported(default is body), ensure element is relatively positioned'
        },
        popupBoxClasses: {
            type: String,
            default: 'flex relative items-center p-3 rounded-md shadow-lg mb-3',
            description: 'classes applied to all types of popup box'
        },
        popupBoxPosition: {
            type: String,
            default: 'top-32 right-6 w-96 max-w-sm transform -translate-x-1/2 fixed flex flex-col z-100000000',
            description: 'classes applied to all types of popup box'
        },
        defaultBoxClasses: {
            type: String,
            default: 'bg-gray-100 text-gray-600',
            description: 'classes applied to default popup box'
        },
        errorBoxClasses: {
            type: String,
            default: 'bg-custom-red-3 text-custom-red-2',
            description: 'classes applied to error popup box'
        },
        successBoxClasses: {
            type: String,
            default: 'bg-custom-green-4 text-custom-green-3',
            description: 'classes applied to success popup box'
        },
        warningBoxClasses: {
            type: String,
            default: 'bg-custom-yellow-2 text-custom-orange-1',
            description: 'classes applied to warning popup box'
        },
        errorIcon: {
            type: String,
            default: 'error.svg',
            description: 'icon file for error alert'
        },
        successIcon: {
            type: String,
            default: 'success.svg',
            description: 'icon file for success alert'
        },
        warningIcon: {
            type: String,
            default: 'warning.svg',
            description: 'icon file for warning'
        },
        maxAlerts: {
            type: Number,
            default: 30,
            description: 'maximum number of alerts to be displayed at a time'
        }
    },

    emits: [
        'update:modelValue'
    ],

    setup (props, { emit }) {
        const alertStack = computed({
            get () {
                return props.modelValue;
            },
            set (value) {
                emit('update:modelValue', value);
            }
        });
        const getIcon = (alertType) => {
            if (alertType === 'error') {
                return props.errorIcon;
            } else if (alertType === 'success') {
                return props.successIcon;
            } else if (alertType === 'warning') {
                return props.warningIcon;
            } else return props.warningIcon;
        };
        const removeAlert = (index) => {
            alertStack.value.splice(index, 1);
        };

        const getTimeoutForMessageType = (type) => {
          switch (type) {
            case 'success': return 4000;
            case 'warning': return 7000;
            case 'error': return 7000;
            default: return 5000;
          }
        }

        watch(
            () => alertStack.value,
            () => {
                if (alertStack.value.length > props.maxAlerts) {
                    alertStack.value.splice(0, alertStack.value.length - props.maxAlerts);
                }

                alertStack?.value.map((msg,idx) => {
                  if (!msg.msgId){
                    msg.msgId = `${idx}__${Math.floor(Math.random()*(10**5))}`

                    setTimeout(() => {
                      let tmpIdx = alertStack?.value.findIndex(m => m.msgId === msg.msgId)
                      if (tmpIdx >= 0) alertStack.value.splice(tmpIdx, 1)
                    }, msg.duration || getTimeoutForMessageType(msg.type))

                  }
                })
            },
            { deep: true }
        );

        return {
            alertStack,
            getIcon,
            removeAlert
        };
    }
};
</script>
