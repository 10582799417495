import { searchAssetsService, fetchAllAssetsFilterService, exportMetadataService } from '@/api/requests/styleGuidesRequests.js';
import { fixFileSize } from '@/helpers/util';

export default {
    namespaced: true,

    state () {
        return {
            assetsList: null,
            assetsFilters: null,
            characterNamesList: null,
            characterActionsList: null,
            descriptiveNameList: null,
            artworkTypeList: null,
            styleGuideNameList: null,
            imageColorationList: null,
            metaDataExport: null,
            searchPage: 1,
            searchLimit: 25
        };
    },

    getters: {
        getAssetsList (state) {
            return state.assetsList;
        },
        getAssetsFilters (state) {
            return state.assetsFilters;
        },
        getCharacterActionsList (state) {
            return state.characterActionsList;
        },
        getDescriptiveNamesList (state) {
            return state.descriptiveNameList;
        },
        getArtworkTypeList (state) {
            return state.artworkTypeList;
        },
        getStyleGuideNameList (state) {
            return state.styleGuideNameList;
        },
        getImageColorationList (state) {
            return state.imageColorationList;
        },
        getCharacterNamesList (state) {
            return state.characterNamesList;
        },
        getSearchPage (state) {
            return state.searchPage;
        },
        getSearchLimit (state) {
            return state.searchLimit;
        }
    },

    mutations: {
        setAssetsList (state, data) {
            data?.data?.forEach(item => item.fileSize = fixFileSize(item.fileSize));
            state.assetsList = data;
            if (data?.facets) {
                const facetsOrder = {
                    'Style Guide Title': 1,
                    'Character Name': 2,
                    'Character Action': 3,
                    'Actor Likeness': 4,
                    'Artwork Type': 5,
                    'Image Coloration': 6
                };

                state.assetsFilters = data.facets.sort((a, b) => {
                    const aOrder = facetsOrder[a.name] || 1000;
                    const bOrder = facetsOrder[b.name] || 1000;
                    return (aOrder > bOrder) ? 1 : -1;
                });

                // state.assetsFilters.forEach(f => f.options?.sort((a, b) => (a.value > b.value) ? 1 : -1));
                state.assetsFilters.forEach(f => f.options?.sort((a, b) => b.count - a.count)); // (a.count || b.count < 0 || 0) ? 1 : -1));

                state.assetsFilters = state.assetsFilters.filter(f => {
                    let includeFacet = true;
                    if (f?.name === 'Iteration' && f?.options?.length < 2) includeFacet = false;
                    if (f?.name === 'Style Guide Title' && f?.options?.length < 2) includeFacet = false;
                    return includeFacet;
                });
            } else if (data && !data.retainFacets) state.assetsFilters = null;
        },
        setAssetsFilters (state, assetsFilters) {
            state.assetsFilters = assetsFilters;
        },
        // clearAllSelectedFilters (state) {
        //     state.assetsFilters.forEach(assetFilter => {
        //         if (state.searchParams[assetFilter.queryParamKey]) {
        //             state.searchParams[assetFilter.queryParamKey] = '';
        //         }
        //     });
        // },
        setCharacterActionsList (state, data) {
            state.characterActionsList = data;
        },
        setDescriptiveNamesList (state, data) {
            state.descriptiveNameList = data;
        },
        setArtworkTypeList (state, data) {
            state.artworkTypeList = data;
        },
        setStyleGuideNameList (state, data) {
            state.styleGuideNameList = data;
        },
        setImageColorationList (state, data) {
            state.imageColorationList = data;
        },
        setCharacterNamesList (state, data) {
            state.characterNamesList = data;
        },
        setSearchPage (state, data) {
            state.searchPage = data;
        },
        setSearchLimit (state, data) {
            state.searchLimit = data;
        },
        updateAssetsListData (state, updatedAssets) {
            if (state.assetsList) {
                if (Array.isArray(updatedAssets)) {
                    updatedAssets.forEach(updatedAsset => {
                        if (updatedAsset?.fileSize) {
                            updatedAsset.fileSize = updatedAsset.fileSize ? fixFileSize(updatedAsset.fileSize) : '';
                        }
                        const matchedAssetIndex = state?.assetsList?.data?.findIndex(asset => asset?.styleGuideAssetId === updatedAsset?.styleGuideAssetId);
                        if (matchedAssetIndex !== -1) {
                            state.assetsList.data.splice(matchedAssetIndex, 1, updatedAsset);
                        }
                    });
                } else {
                    if (updatedAssets?.fileSize) {
                        updatedAssets.fileSize = updatedAssets?.fileSize ? fixFileSize(updatedAssets.fileSize) : '';
                    }
                    state.assetsList.data = state.assetsList?.data?.map(
                        asset => asset.id === updatedAssets.styleGuideAssetId ? updatedAssets : asset
                    );
                }
            }
        }
    },

    actions: {
        async exportMetadata ({ commit }, { params }) {
            const { data: { data } } = await exportMetadataService(params);
            return data;
        },
        async fetchAssetsList ({ commit, state, getters, rootGetters }, { params, retainFacets }) {
            const selectedLicensee = rootGetters['auth/getSelectedLicensee'];
            if (selectedLicensee) params.selectedLicenseeName = selectedLicensee.licenseeName;

            const { data: { data } } = await searchAssetsService({
                ...params,
                page: getters.getSearchPage,
                limit: getters.getSearchLimit
            });

            if (retainFacets) {
                delete data.facets;
                data.retainFacets = true;
            }

            // Sort the asset based on displayOrder .. if browsing the Style Guide
            if (params.mrlc_attr_tier_1) {
                data.data.sort((a, b) => (parseInt(a.displayOrder) || 100000) > (parseInt(b.displayOrder) || 100000) ? 1 : -1);
            }

            commit('setAssetsList', data);
            return data;
        },

        async fetchDescriptiveNamesList ({ commit }, { params }) {
            const { data } = await fetchAllAssetsFilterService({ filter: 'descriptive-keywords', styleGuideName: params.styleGuideName });
            if (!data?.data || !data.data.length) data.data = [];
            const __tmp = [];
            data.data.filter(e => e).map(e =>
                e.split('|')
                    .map(t => t.trim())
                    .filter(t => t)
                    .map(t => !__tmp.includes(t) ? __tmp.push(t) : '')
            );
            data.data = __tmp;
            data.data = data.data.sort();
            return data;
        },
        async fetchCharacterActionsList ({ commit }, { params }) {
            const { data } = await fetchAllAssetsFilterService({ filter: 'character-actions', styleGuideName: params.styleGuideName });
            if (!data?.data || !data.data.length) data.data = [];
            data.data = data.data.sort();
            return data;
        },
        async fetchArtworkTypeList ({ commit }, { params }) {
            const { data } = await fetchAllAssetsFilterService({ filter: 'artwork-types', styleGuideName: params.styleGuideName });
            if (!data?.data || !data.data.length) data.data = [];
            data.data = data.data.sort();
            return data;
        },
        async fetchStyleGuideNameList ({ commit }) {
            const { data } = await fetchAllAssetsFilterService({ filter: 'style-guide-names' });
            if (!data?.data || !data.data.length) data.data = [];
            data.data = data.data.sort();
            return data;
        },

        async fetchCharacterNamesList ({ commit }, { params }) {
            const { data } = await fetchAllAssetsFilterService({ filter: 'character-names', ...params });
            if (!data?.data || !data.data.length) data.data = [];
            data.data = data.data.sort();
            return data;
        },

        async fetchImageColorationList ({ commit }) {
            const { data } = await fetchAllAssetsFilterService({ filter: 'image-coloration' });
            commit('setImageColorationList', data);
        },

        setSearchPage ({ commit }, data) {
            commit('setSearchPage', data);
        },
        setSearchLimit ({ commit }, data) {
            commit('setSearchLimit', data);
        },
        resetAssets ({ commit }) {
            commit('setAssetsFilters', null);
            commit('setAssetsList', null);
        },
        updateAssetsList ({ commit }, updatedList) {
            commit('updateAssetsListData', updatedList);
        }
    }
};
