import { fetchDrmStyleGuideContractListService, fetchDrmStyleGuideCharactersListService } from '@/api/requests/baDrmServicesRequests';

export default {
    namespaced: true,

    state () {
        return {
            completeStyleGuidesList: null,
            drmStyleGuidesList: null,
            drmStyleGuidesCharactersList: null
        };
    },

    getters: {
        getCompleteStyleGuidesList (state) {
            return state.completeStyleGuidesList;
        },
        getDrmStyleGuidesList (state) {
            return state.drmStyleGuidesList;
        },
        getDrmStyleGuidesCharactersList (state) {
            return state.drmStyleGuidesCharactersList;
        }
    },

    mutations: {
        setCompleteStyleGuidesList (state, newStyleGuideList) {
            state.completeStyleGuidesList = newStyleGuideList;
        },
        setDrmStyleGuidesList (state, newDrmStyleGuideList) {
            state.drmStyleGuidesList = newDrmStyleGuideList;
        },
        setDrmStyleGuidesCharactersList (state, newDrmStyleGuideCharactersList) {
            state.drmStyleGuidesCharactersList = newDrmStyleGuideCharactersList;
        }
    },
    actions: {
        async fetchAllStyleGuidesList ({ commit }, { params }) {
            const { data: { data } } = await fetchDrmStyleGuideContractListService(params);
            console.log(`Fetched Style Guides : ${data.pageCount} .. ${data.totalCount}`)
            commit('setCompleteStyleGuidesList', data);
        },
        async fetchDrmStyleGuideContractList ({ commit }, { params, onlyReturnValues }) {
            const { data: { data } } = await fetchDrmStyleGuideContractListService(params);
            if (onlyReturnValues) return data
            commit('setDrmStyleGuidesList', data);
        },
        async fetchDrmStyleGuideCharactersList ({ commit }, { params, id, onlyReturnValues }) {
            const { data: { data } } = await fetchDrmStyleGuideCharactersListService(params, id);
            if (onlyReturnValues) return data
            commit('setDrmStyleGuidesCharactersList', data);
        },
        resetAll ({ commit }) {
            commit('setCompleteStyleGuidesList', null);
            commit('setDrmStyleGuidesList', null);
            commit('setDrmStyleGuidesCharactersList', null);
        }
    }
};
