
import { OktaAuth } from '@okta/okta-auth-js';

const authClient = new OktaAuth({
    issuer: process.env.VUE_APP_OKTA_ISSUER,
    clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URI,
    scopes: ['openid', 'profile', 'api:read', 'api:write', 'email']
});

export default {
    loginRedirect () {
        return authClient.signInWithRedirect({ originalUri: '/' });
    },

    logoutUser () {
        return authClient.signOut();
    },

    async refreshIdToken () {
        const newAccessToken = await authClient.tokenManager.renew('idToken');
        return newAccessToken;
    },

    async refreshAccessToken () {
        const newAccessToken = await authClient.tokenManager.renew('accessToken');
        return newAccessToken;
    },

    async refreshIdAndAccessToken () {
        const idToken = await authClient.tokenManager.renew('idToken');
        const accessToken = await authClient.tokenManager.renew('accessToken');
        return { idToken, accessToken };
    },

    async fetchOktaTokens () {
        const res = await authClient.token.parseFromUrl();
        authClient.tokenManager.setTokens(res.tokens);
        return res.tokens;
    },

    fetchUserInfo (accessTokenObject, idTokenObject) {
        return authClient.token.getUserInfo(accessTokenObject, idTokenObject);
    }
};
