import { STYLE_GUIDES_ENDPOINT, SEARCH_ASSETS_ENDPOINT, ASSETS_FILTERS_ENDPOINT, BASE_API_ENDPOINT, EXPORT_META_DATA_STYLE_GUIDES } from '@/api/endpointConstants.js';
import { filterObject } from '@/helpers/util';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';

// intialize local instance
const styleGuideAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + STYLE_GUIDES_ENDPOINT
});

// request interceptor
styleGuideAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchStyleGuideDirectoryService (params) {
    return styleGuideAxiosInstance({
        method: 'GET',
        params: filterObject(params)
    });
}

export function fetchStyleGuideBannerService (params) {
    return styleGuideAxiosInstance({
        method: 'GET',
        params: filterObject(params)
    });
}

export function searchAssetsService (params) {
    if (params.keyword && params.keyword.includes(' ')){
      params.keyword = `"${params.keyword}"`
    }
    return styleGuideAxiosInstance({
        method: 'GET',
        url: SEARCH_ASSETS_ENDPOINT,
        params: filterObject(params)
    });
}

export function fetchAllAssetsFilterService (params) {
    return styleGuideAxiosInstance({
        method: 'GET',
        url: ASSETS_FILTERS_ENDPOINT,
        params
    });
}

export function exportMetadataService (data) {
    return styleGuideAxiosInstance({
        method: 'POST',
        url: EXPORT_META_DATA_STYLE_GUIDES,
        data
    });
}
