import { BASE_REPORT_API_ENDPOINT, MASTER_DATA_SUBMISSION_ENDPOINT, PRODUCT_LINE_REPORT_PDF_ENDPOINT } from '@/api/endpointConstants.js';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';

// intialize local instance
const baProductLineAxiosInstance = axios.create({
    baseURL: BASE_REPORT_API_ENDPOINT + MASTER_DATA_SUBMISSION_ENDPOINT
});

baProductLineAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function productLineReportService (data) {
    return baProductLineAxiosInstance({
        method: 'POST',
        url: PRODUCT_LINE_REPORT_PDF_ENDPOINT,
        data
    });
}
