import { BASE_MOCK_API_ENDPOINT, BRAND_ASSURANCE_ENDPOINT, LICENSEE_CONTACTS_ENDPOINT, LICENSEE_CONTRACT_NUMBER_ENDPOINT, LICENSEE_DETAILS_ENDPOINT, LICENSEE_ENDPOINT, LICENSEE_NAMES_ENDPOINT } from '@/api/endpointConstants.js';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import { filterObject } from '@/helpers/util';
import axios from 'axios';

// intialize local instance
const baLicenseeAxiosInstance = axios.create({
    baseURL: BASE_MOCK_API_ENDPOINT + BRAND_ASSURANCE_ENDPOINT + LICENSEE_ENDPOINT
});

// request interceptor
baLicenseeAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchLicenseeNamesService (params) {
    return baLicenseeAxiosInstance({
        method: 'GET',
        url: LICENSEE_NAMES_ENDPOINT,
        params: filterObject(params)
    });
}

export function fetchLicenseeContractNumberService (params) {
    return baLicenseeAxiosInstance({
        method: 'GET',
        url: LICENSEE_CONTRACT_NUMBER_ENDPOINT,
        params: filterObject(params)
    });
}

export function fetchLicenseeDetailsService (name) {
    return baLicenseeAxiosInstance({
        method: 'GET',
        url: `${LICENSEE_DETAILS_ENDPOINT}/${name}`
    });
}

export function fetchLicenseeContactListService (name) {
    return baLicenseeAxiosInstance({
        method: 'GET',
        url: `${LICENSEE_CONTACTS_ENDPOINT}/${name}`
    });
}
