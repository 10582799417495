import { addToBasketService, fetchBasketService, removeFromBasketService, clearAllBasketService } from '@/api/requests/styleGuidesBasket.js';
import { fixFileSize } from '@/helpers/util';

export default {
    namespaced: true,

    state () {
        return {
            basketList: null
        };
    },

    getters: {
        getBasketList (state) {
            return state.basketList;
        }
    },

    mutations: {
        setBasketList (state, data) {
            if (!data) data = []
            data.forEach(item => item.fileSize = fixFileSize(item.fileSize));
            state.basketList = [...data];
        },
        removeItemFromBasketList (state, basketItem) {
            state.basketList = state.basketList.filter(item => item.id !== basketItem.id);
        },
        addItemstoBasketList (state, basketItem) {
            if (state.basketList === null) {
                state.basketList = [];
            }
            if (Array.isArray(basketItem)) {
                basketItem.forEach(item => item.fileSize = fixFileSize(item.fileSize));
                basketItem = basketItem.filter(newItem => !state.basketList.some(storedItem => storedItem.id === newItem.id));
                state.basketList = state.basketList.concat(basketItem);
            } else {
                basketItem.fileSize = fixFileSize(basketItem.fileSize)
                if (!state.basketList.some(storedItem => storedItem.id === basketItem.id)) {
                    state.basketList.push(basketItem);
                }
            }
        }
    },

    actions: {
        async fetchBasketList ({ commit, state }) {
            const { data: { data } } = await fetchBasketService();
            // if (state.basketList && state.basketList.length) {
            //     // @NOTE- this mutation commit is for persisting basket data (remove it for a complete functional backend with DAM integrations)
            //     commit('addItemstoBasketList', data);
            // } else {
            commit('setBasketList', data);
            // }
        },
        async removeFromBasket ({ commit }, payload) {
            if (Array.isArray(payload)) {
                const promiseStack = payload.map(item => removeFromBasketService(item.id));
                await Promise.all(promiseStack);
                payload.forEach(item => {
                    commit('removeItemFromBasketList', item);
                });
            } else {
                await removeFromBasketService(payload.id);
                commit('removeItemFromBasketList', payload);
            }
        },
        async removeFromBasket2 ({ commit }, id) {
            await removeFromBasketService(id);
            // commit('removeItemFromBasketSetter', id);
            // await fetchBasketService();
        },
        async addToBasket ({ commit }, payload) {
            await addToBasketService(payload);
            commit('addItemstoBasketList', payload);
            // if (Array.isArray(payload)) {
            //     const promiseStack = payload.map(item => addToBasketService(item));
            //     await Promise.all(promiseStack);
            //     payload.forEach(item => {
            //         commit('addItemstoBasketList', item);
            //     });
            // } else {
            //     await addToBasketService(payload);
            //     commit('addItemstoBasketList', payload);
            // }
        },
        async clearBasket ({ commit, state, dispatch }) {
            if (state.basketList && state.basketList.length) {
                await dispatch('removeFromBasket', state.basketList);
                commit('setBasketList', null);
            }
        },
        async clearAllBasket ({ commit }) {
            await clearAllBasketService();
        },
        clearBasketCache ({ commit }) {
            commit('setBasketList', null);
        }
    }
};
