import { fetchSubmissionPossibleStagesService, fetchSubmissionStagesService, fetchSubmissionStatusService, fetchSubmissionStepsService } from '@/api/requests/masterDataRequests.js';

export default {
    namespaced: true,

    state () {
        return {
            possibleStageList: null,
            stageList: null,
            stepList: null,
            statusList: null
        };
    },

    getters: {
        getPossibleStageList (state) {
            return state.possibleStageList;
        },
        getStageList (state) {
            return state.stageList;
        },
        getStepList (state) {
            return state.stepList;
        },
        getStatusList (state) {
            return state.statusList;
        }
    },

    mutations: {
        setPossibleStageList (state, possibleStages) {
            state.possibleStageList = possibleStages;
        },
        setStageList (state, stages) {
            state.stageList = stages;
        },
        setStepsList (state, steps) {
            state.stepList = steps;
        },
        setStatusList (state, status) {
            state.statusList = status;
        }
    },

    actions: {
        async fetchStages ({ commit }, { params }) {
            const { data: { data } } = await fetchSubmissionStagesService(params);
            commit('setStageList', data);
        },
        async fetchStatusList ({ commit }, { params }) {
            const { data: { data } } = await fetchSubmissionStatusService(params);
            commit('setStatusList', data);
        },
        async fetchSteps ({ commit }, { params }) {
            const { data: { data } } = await fetchSubmissionStepsService(params);
            commit('setStepsList', data);
        },
        async fetchPossibleStages ({ commit }, { params }) {
            if (!params?.stageKey) return;
            const { data: { data } } = await fetchSubmissionPossibleStagesService(params);
            commit('setPossibleStageList', data);
        },
        resetSubmissionRouting ({ commit }) {
            commit('setPossibleStageList', null);
            commit('setStageList', null);
            commit('setStatusList', null);
            commit('setStepsList', null);
        }
    }
};
