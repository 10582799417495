import { STYLE_GUIDES_ENDPOINT, BASKET_ENDPOINT, BASE_API_ENDPOINT } from '@/api/endpointConstants.js';
// import { filterObject } from '@/helpers/util';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';

// intialize local instance
const basketAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + STYLE_GUIDES_ENDPOINT + BASKET_ENDPOINT
});

// request interceptor
basketAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchBasketService () {
    return basketAxiosInstance({
        method: 'GET'
    });
};

export function addToBasketService (data) {
    return basketAxiosInstance({
        method: 'POST',
        data
    });
};

export function removeFromBasketService (assetId) {
    return basketAxiosInstance({
        method: 'DELETE',
        url: `/${assetId}`
    });
};

export function clearAllBasketService () {
    return basketAxiosInstance({
        method: 'DELETE'
    });
}
