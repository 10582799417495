/**
 * Removes properties from object having falsy values('', null, undefined etc. excluding number 0) and empty Array
 * @param {Object} obj
 * @returns {Object} Filtered Object
 */
export function filterObject ({ ...obj }) {
    const objectKeys = Object.keys(obj);
    if (objectKeys.length) {
        objectKeys.forEach((key) => {
            if (Array.isArray(obj[key]) && !obj[key].length) {
                delete obj[key];
            } else if (typeof obj[key] !== 'number' && !(obj[key] instanceof Number) && !obj[key]) {
                delete obj[key];
            }
        });
    }
    return obj;
}

/**
 * formats date based on pattern
 * @param {Date | String} dateObj
 * @param {String} pattern
 * @param {Boolean} isTimeFormat12Hrs
 * @returns {String} Formatted Date String
 */
export function formatDate (dateObj, pattern = 'MM/DD/YYYY', isTimeFormat12Hrs = false) {
    if (!dateObj) {
        return '';
    }
    if (typeof dateObj === 'string') {
        dateObj = new Date(dateObj);
    }
    let [date, month, year, hours, minutes, seconds] = [dateObj.getDate(), dateObj.getMonth() + 1, dateObj.getFullYear(), dateObj.getHours(), dateObj.getMinutes(), dateObj.getSeconds()];
    month = (month < 10 ? '0' : '') + month;
    date = (date < 10 ? '0' : '') + date;
    let timePeriod = '';
    if (isTimeFormat12Hrs) {
        timePeriod = 'PM';
        if (hours < 12) {
            timePeriod = 'AM';
        } else {
            hours -= 12;
        }
    }
    hours = (hours < 10 ? '0' : '') + hours;
    return pattern.replace('DD', date).replace('MM', month).replace('YYYY', year).replace('HH', hours).replace('MM', `${minutes} ${timePeriod}`).replace('SS', seconds);
}

/**
 * returns an array with unique values and removing duplicated ones via ES6 Sets
 * Note: Only array with primitive values are supported, do not use on complex arrays
 * @param {Array} arr
 * @returns {Array}
 */
export function getDistinctArray (arr) {
    const set = new Set(arr);
    return [...set];
}

/**
 * Saves binary file by creating a blob and assigning its URL to a temporary invisible anchor element
 * @param {Object} binaryData
 * @param {String} fileName
 */
export function saveFileFromBlob (binaryData, fileName) {
    const downloadUrl = window.URL.createObjectURL(new Blob([binaryData]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function fixFileSize (filesizeStr) {
    if (!filesizeStr) return 0;

    filesizeStr = `${filesizeStr}`.toLowerCase();
    let [size, unit] = filesizeStr.split(/([^0-9]+)|([^a-zA-Z]+)/).filter(e => e);
    let ret = 0;
    if (size) {
        size = size.trim();
        ret = parseInt(size);
        if (unit) {
            if (unit.startsWith('b')) ret = ret;
            if (unit.startsWith('k')) ret *= 1024;
            if (unit.startsWith('m')) ret *= (1024 * 1024);
            if (unit.startsWith('g')) ret *= (1024 * 1024 * 1024);
            if (unit.startsWith('t')) ret *= (1024 * 1024 * 1024 * 1024);
            if (unit.startsWith('p')) ret *= (1024 * 1024 * 1024 * 1024 * 1024);
        }
    }
    return ret;
}

export function printFileSize (filesizeNumeric) {
    if (!filesizeNumeric) return '';

    return filesizeNumeric > (1024 * 1024) ? Math.ceil(filesizeNumeric / (1024 * 1024)) + ' MB'
        : filesizeNumeric > 1024 ? Math.ceil(filesizeNumeric / 1024) + ' KB'
            : filesizeNumeric + ' B';
}

export function formatFilename (string) {
    string = string.replace(/[)(]/g, '').replace(/  +/g, ' ');
    string = string.trim().split(' ').join('_');
    return string;
};
