import { fetchAllUsersService, fetchUserDetailsByUserNameService, fetchAllRolesService, fetchUserPreferencesService, updateUserRolesService, saveUserPreferencesService } from '@/api/requests/usersRequests.js';

export default {
    namespaced: true,

    state () {
        return {
            usersList: null,
            userNewDetailsList: null,
            allRolesList: null,
            userNewPreference: null,
            userNewPreferenceList: null
        };
    },

    getters: {
        getUsersList (state) {
            return state.usersList;
        },
        getUserDetailsList (state) {
            return state.userNewDetailsList;
        },
        getAllRolesList (state) {
            return state.allRolesList;
        },
        getUserPreference (state) {
            return state.userNewPreference;
        },
        getUserPreferenceList (state) {
            return state.userNewPreferenceList;
        }
    },

    mutations: {
        setUsersList (state, usersList) {
            state.usersList = usersList;
        },
        setUserDetailsList (state, userDetailsList) {
            state.userNewDetailsList = userDetailsList;
        },
        setAllRolesList (state, rolesList) {
            state.allRolesList = rolesList;
        },
        setUserPreferences (state, userPreference) {
            state.userNewPreference = userPreference;
        },
        setUserPreferencesList (state, userPreferenceList) {
            state.userNewPreferenceList = userPreferenceList;
        }
    },

    actions: {
        async fetchUsersList ({ commit }, { params }) {
            const { data: { data } } = await fetchAllUsersService(params);
            commit('setUsersList', data);
        },
        async fetchUserDetailsByUserName ({ commit }, { params }) {
            const { data: { data } } = await fetchUserDetailsByUserNameService(params);
            return data;
        },
        async fetchAllRoles ({ commit }) {
            const { data: { data } } = await fetchAllRolesService();
            commit('setAllRolesList', data);
        },
        async updateUserRoles ({ commit }, { bodyPayLoad }) {
            await updateUserRolesService(bodyPayLoad);
        },
        async saveUserPreferences ({ commit }, { params }) {
            const { data: { data } } = await saveUserPreferencesService(params);
            commit('setUserPreferencesList', data);
        },
        async fetchUserPreferences ({ commit }) {
            const { data: { data } } = await fetchUserPreferencesService();
            commit('setUserPreferencesList', data);
        }
    }
};
