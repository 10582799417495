export const LOGIN_PATH = 'login';
export const LOGIN_NAME = 'Login';
export const PORTAL_SELECTION_PATH = 'portal-selection';
export const PORTAL_SELECTION_NAME = 'PortalSelection';
export const LICENSEE_SELECTION_PATH = 'licensee-selection';
export const LICENSEE_SELECTION_NAME = 'LicenseeSelection';
export const LOGIN_REDIRECT_PATH = 'login/callback';
export const LOGIN_REDIRECT_NAME = 'LoginRedirect';
// style guide routes
export const STYLE_GUIDES_PATH = 'style-guides';
export const STYLE_GUIDES_NAME = 'StyleGuide';
export const BASKET_PATH = 'basket';
export const BASKET_NAME = 'Basket';
// Brand assurance routes
export const BRAND_ASSURANCE_PATH = 'brand-assurance';
export const BRAND_ASSURANCE_NAME = 'BrandAssurance';
export const BA_INBOX_PATH = 'inbox';
export const BA_INBOX_NAME = 'BAInbox';
export const BA_ADVANCE_SEARCH_PATH = 'advance-search';
export const BA_ADVANCE_SEARCH_NAME = 'BAAdvanceSearch';
export const BA_COLLABORATION_PATH = 'collaboration';
export const BA_COLLABORATION_NAME = 'BACollaboration';
export const BA_COMPLETED_SUBMISSIONS_PATH = 'completed-submissions';
export const BA_COMPLETED_SUBMISSIONS_NAME = 'BACompletedSubmissions';
export const BA_DRAFTS_PATH = 'drafts';
export const BA_DRAFTS_NAME = 'BADrafts';
export const BA_INFLIGHT_SUBMISSIONS_PATH = 'in-flight-submissions';
export const BA_INFLIGHT_SUBMISSIONS_NAME = 'BAInFlightSubmissions';
export const BA_NEW_SUBMISSION_REQUEST_PATH = 'new-submission-request';
export const BA_NEW_SUBMISSION_REQUEST_NAME = 'BANewSubmissionRequest';
export const BA_PRODUCT_LINE_REPORT_PATH = 'product-line-report';
export const BA_PRODUCT_LINE_REPORT_NAME = 'BAProductLineReport';
export const BA_SYSTEM_ADMIN_PATH = 'system-admininstration';
export const BA_SYSTEM_ADMIN_NAME = 'BASystemAdmininstration';
