import { BASE_API_ENDPOINT, BASE_MOCK_API_ENDPOINT, BRAND_ASSURANCE_ENDPOINT, COLLABORATIONS_ENDPOINT, COLLABORATION_DETATILS_ENDPOINT, COLLABORATION_STATUS_ENDPOINT, COLLABORATION_TEMPLATE_ENDPOINT, DELETE_COLLABORATION_TEMPLATE_ENDPOINT, MOCK_COLLABORATIONS_ENDPOINT, MOCK_COLLABORATORS_ENDPOINT } from '@/api/endpointConstants.js';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import { filterObject } from '@/helpers/util';
import axios from 'axios';

// @TO-DO's: remove mock api instance once mock services are not required for collaborations

// intialize local instance
const baMockCollaborationsAxiosInstance = axios.create({
    baseURL: BASE_MOCK_API_ENDPOINT + BRAND_ASSURANCE_ENDPOINT + MOCK_COLLABORATIONS_ENDPOINT
});

// request interceptor
baMockCollaborationsAxiosInstance.interceptors.request.use(request => {
    console.log('>>> REMOVE MOCK : baMockCollaborationsAxiosInstance')
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchCollaboratorsService (collabId, params) {
    return baMockCollaborationsAxiosInstance({
        method: 'GET',
        url: `/${collabId}${MOCK_COLLABORATORS_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function endCollaborationService (data) {
    return baMockCollaborationsAxiosInstance({
        method: 'PUT',
        data
    });
}

export function fetchCollaborationByIdService (collabId) {
    return baMockCollaborationsAxiosInstance({
        method: 'GET',
        url: `/${collabId}`
    });
}

export function deleteCollaboratorsService (collabId, data) {
    return baMockCollaborationsAxiosInstance({
        method: 'DELETE',
        url: `${collabId}${MOCK_COLLABORATORS_ENDPOINT}`,
        data
    });
}

// @NOTE: real services starts

// intialize local instance
const baCollaborationsAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + COLLABORATIONS_ENDPOINT
});

// request interceptor
baCollaborationsAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchCollaborationsService (params) {
    return baCollaborationsAxiosInstance({
        method: 'GET',
        params: filterObject(params)
    });
}

export function fetchCollaborationDetailsService (collabId) {
    return baCollaborationsAxiosInstance({
        method: 'GET',
        url: `/${collabId}${COLLABORATION_DETATILS_ENDPOINT}`
    });
}

export function fetchCollaborationTemplateService (userName) {
    return baCollaborationsAxiosInstance({
        method: 'GET',
        url: `${COLLABORATION_TEMPLATE_ENDPOINT}/${userName}`
    });
}

export function updateCollaborationTemplateService (data) {
    return baCollaborationsAxiosInstance({
        method: 'PUT',
        url: COLLABORATION_TEMPLATE_ENDPOINT,
        data
    });
}

export function deleteCollaborationTemplateService (data) {
    return baCollaborationsAxiosInstance({
        method: 'POST',
        url: `${COLLABORATION_TEMPLATE_ENDPOINT}${DELETE_COLLABORATION_TEMPLATE_ENDPOINT}`,
        data
    });
}

export function updateCollaborationService (data) {
    return baCollaborationsAxiosInstance({
        method: 'PUT',
        data
    });
}

export function closeCollaborationService (collabId, data) {
    return baCollaborationsAxiosInstance({
        method: 'PUT',
        url: `/${collabId}${COLLABORATION_STATUS_ENDPOINT}`,
        data
    });
}
