import { closeCollaborationService, deleteCollaborationTemplateService, deleteCollaboratorsService, endCollaborationService, fetchCollaborationDetailsService, fetchCollaborationsService, fetchCollaborationTemplateService, fetchCollaboratorsService, updateCollaborationService, updateCollaborationTemplateService } from '@/api/requests/baCollaborationsRequests';
import { fetchSubmissionComment, generateIdentifierService } from '@/api/requests/baSubmissionRequests';

export default {
    namespaced: true,

    state () {
        return {
            collaborationsList: null,
            existingCollaboratorsList: null,
            collaborationDetails: null,
            collaborationTemplateList: null,
            collaborationCommentsList: null
        };
    },

    getters: {
        getCollaborationsList (state) {
            return state.collaborationsList;
        },
        getExistingCollaboratorsList (state) {
            return state.existingCollaboratorsList;
        },
        getCollaborationsDetails (state) {
            return state.collaborationDetails;
        },
        getCollaborationTemplateList (state) {
            return state.collaborationTemplateList;
        },
        getCollaborationCommentsList (state) {
            return state.collaborationCommentsList;
        }
    },

    mutations: {
        setCollaborationsList (state, newCollabList) {
            state.collaborationsList = newCollabList;
        },
        setExistingCollaboratorsList (state, existingCollaboratorsList) {
            state.existingCollaboratorsList = existingCollaboratorsList;
        },
        removeCollaborations (state, collabIds) {
            if (state.collaborationsList && state.collaborationsList.data) {
                state.collaborationsList.data = state.collaborationsList.data.filter(collab => !collabIds.includes(collab.id));
            }
        },
        setCollaborationsDetails (state, collabDetails) {
            state.collaborationDetails = collabDetails;
        },
        deleteCollabartors (state, userIds) {
            if (state.existingCollaboratorsList && state.existingCollaboratorsList.data.length > 0) {
                state.existingCollaboratorsList.data = state.existingCollaboratorsList.data.filter(collaborator => !userIds.includes(collaborator.userId));
            }
        },
        setCollaborationTemplateList (state, collaborationTemplateList) {
            state.collaborationTemplateList = collaborationTemplateList;
        },
        updateCollaborationTemplateList (state, updatedCollabortorsList) {
            let matchedCollabList = null;
            if (state.collaborationTemplateList?.length) {
                matchedCollabList = state.collaborationTemplateList.find(collabList => collabList.collaborationTemplateId === updatedCollabortorsList.collaborationTemplateId);
            }
            if (matchedCollabList) {
                // update existing collaborator list in templates
                state.collaborationTemplateList = state.collaborationTemplateList.map(collabList => collabList.collaborationTemplateId === updatedCollabortorsList.collaborationTemplateId ? { ...updatedCollabortorsList } : collabList);
            } else {
                // add new collaborator list in templates
                state.collaborationTemplateList.push(updatedCollabortorsList);
            }
        },
        deleteCollaborationTemplateList (state, collaborationTemplateId) {
            if (state.collaborationTemplateList?.length) {
                state.collaborationTemplateList = state.collaborationTemplateList.filter(collabList => collabList.collaborationTemplateId !== collaborationTemplateId);
            }
        },
        setCollaborationCommentsList (state, collabCommentsList) {
            state.collaborationCommentsList = collabCommentsList;
        }
    },

    actions: {
        async fetchCollaborations ({ commit }, { params }) {
            const { data: { data } } = await fetchCollaborationsService(params);
            commit('setCollaborationsList', data);
        },
        async fetchExistingCollaborators ({ commit }, { params, collabId }) {
            const { data: { data } } = await fetchCollaboratorsService(collabId, params);
            commit('setExistingCollaboratorsList', data);
        },
        async deleteCollaborators ({ commit }, { collabId, bodyPayload }) {
            await deleteCollaboratorsService(collabId, bodyPayload);
            commit('deleteCollabartors', bodyPayload.userIds);
        },
        async endCollaborations ({ commit }, collabPayload) {
            await endCollaborationService(collabPayload);
            commit('removeCollaborations', collabPayload.collabIds);
            // @NOTE- temporary mutation commit (if functional backend returns updated list of collabs, use setCollaborationsList to set with updated collab list)
        },
        async fetchCollaborationDetails ({ commit }, { collabId }) {
            const { data: { data } } = await fetchCollaborationDetailsService(collabId);
            commit('setCollaborationsDetails', data);
        },
        async fetchCollaborationTemplate ({ commit }, { userName }) {
            const { data: { data } } = await fetchCollaborationTemplateService(userName);
            commit('setCollaborationTemplateList', data);
        },
        async saveCollaborationTemplate ({ commit }, { bodyPayload }) {
            const { data: { data } } = await updateCollaborationTemplateService(bodyPayload);
            commit('updateCollaborationTemplateList', data);
        },
        async deleteCollaborationTemplate ({ commit }, { bodyPayload }) {
            await deleteCollaborationTemplateService(bodyPayload);
            commit('deleteCollaborationTemplateList', bodyPayload.collaborationTemplateId);
        },
        async fetchCollaborationComments ({ commit, rootGetters }, { params }) {
            const { data: { data } } = await fetchSubmissionComment(params);

            const userId = rootGetters['auth/getUserId']

            let tmpData = data?.map(c => {
                if (c.creatUserId === userId) c.alignLeft = true
                return c
              })
              .sort((a, b) => {
                if (!a.creatTmstmp || !b.creatTmstmp) return
                let t1 = new Date(a.creatTmstmp), t2 = new Date(b.creatTmstmp);
                if (t1 < t2) return -1;
                if (t1 > t2) return 1;
                return 0;
              })

            commit('setCollaborationCommentsList', tmpData);
        },
        async updateCollaboration ({ commit }, { bodyPayload }) {
            const { data: { data } } = await updateCollaborationService(bodyPayload);
            commit('setCollaborationsDetails', data);
        },
        async closeCollaboration (store, { bodyPayload, collabId }) {
            await closeCollaborationService(collabId, bodyPayload);
        },
        async generateNewCollaborationId ({ commit }, { params }) {
            const { data: { data } } = await generateIdentifierService(params);
            return data?.identifier || ''
        }
    }
};
