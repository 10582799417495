import { BASE_API_ENDPOINT, TRANSACTION_HANDLER_ENDPOINT } from '@/api/endpointConstants.js';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';

// intialize local instance
const transactionHandlerAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + TRANSACTION_HANDLER_ENDPOINT
});

// request interceptor
transactionHandlerAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function updateSubmissionService (data) {
    return transactionHandlerAxiosInstance({
        method: 'POST',
        data
    });
}
