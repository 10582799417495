<template>
  <component
    :is="tag"
    v-bind="attrs"
    ref="wrapper"
    :data-name="iconName"
    v-html="require(`!html-loader!@/assets/images/${src}`)"
  />
</template>

<script>
import { computed, onMounted, ref } from 'vue';

export default {
    name: 'BaseSvg',

    props: {
        tag: {
            type: String,
            default: 'div',
            description: 'Wrapper component'
        },
        src: {
            type: String,
            required: true,
            description: 'image path(image will searched in assets->images)'
        },
        svgAttributes: {
            type: Object,
            default: () => {},
            description: 'svg attributes'
        }
    },

    setup (props, { attrs }) {
        onMounted(() => {
            if (props.svgAttributes && Object.keys(props.svgAttributes).length) {
                setSvgAttributes();
            }
        });

        const wrapper = ref(null);

        // set attributes of inline svg
        const setSvgAttributes = () => {
            const svg = wrapper.value.children[0];
            Object.entries(props.svgAttributes).forEach(([attribute, value]) => {
                svg.setAttribute(attribute, value);
            });
        };

        const iconName = computed(() => {
            return props.src.split('/').slice(-1)[0];
        });

        return {
            wrapper,
            attrs,
            iconName
        };
    }
};
</script>
