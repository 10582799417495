export default {
    beforeMount (el, binding) {
        el.scrollTo = () => {
            if (typeof binding.value !== 'string') {
                console.error('Error: Binded value should be string.');
                return;
            }
            const targetElement = document.querySelector(binding.value);
            if (targetElement) {
                targetElement.scrollIntoView({ block: 'center' });
            } else {
                console.warn('Warning: Target element not found.');
            }
        };
        el.addEventListener('click', el.scrollTo);
    },
    unmounted (el) {
        el.removeEventListener('click', el.scrollTo);
    }
};
