export default {
    beforeMount (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (binding.modifiers && Object.keys(binding.modifiers).length) {
                if ('prevent' in binding.modifiers && binding.modifiers.prevent) {
                    event.preventDefault();
                }
                if ('stop' in binding.modifiers && binding.modifiers.stop) {
                    event.stopPropagation();
                }
            }
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
};
