import { STYLE_GUIDES_ENDPOINT, SAVE_SEARCHES_ENDPOINT, BASE_API_ENDPOINT } from '@/api/endpointConstants.js';
// import { filterObject } from '@/helpers/util';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';

// intialize local instance
const savedSearchAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + STYLE_GUIDES_ENDPOINT + SAVE_SEARCHES_ENDPOINT
});

// request interceptor
savedSearchAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchSavedSearchesService () {
    return savedSearchAxiosInstance({
        method: 'GET'
    });
};

export function saveAssetsSearchService (data) {
    return savedSearchAxiosInstance({
        method: 'POST',
        data
    });
};

export function deleteSavedSearchService (searchId) {
    return savedSearchAxiosInstance({
        method: 'DELETE',
        url: `/${searchId}`
    });
};
