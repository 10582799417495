import {
  BASE_API_ENDPOINT, MASTER_DATA_ENDPOINT, MASTER_REQUESTED_STAGE_ENDPOINT, MASTER_DATA_POSSIBLE_STAGES_ENDPOINT,
  MASTER_DATA_STAGES_ENDPOINT, MASTER_DATA_STATUS_ENDPOINT, MASTER_DATA_STEPS_ENDPOINT, MASTER_DATA_SUBMISSION_ENDPOINT,
} from '@/api/endpointConstants.js';
import { filterObject } from '@/helpers/util';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';

// intialize local instance
const masterDataAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + MASTER_DATA_ENDPOINT
});

// request interceptor
masterDataAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function masterDataLicenseeRequestedStageService (params) {
    return masterDataAxiosInstance({
        method: 'GET',
        url: `${MASTER_REQUESTED_STAGE_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchSubmissionStagesService (params) {
    return masterDataAxiosInstance({
        method: 'GET',
        url: MASTER_DATA_STAGES_ENDPOINT,
        params: filterObject(params)
    });
}

export function fetchSubmissionStatusService (params) {
    return masterDataAxiosInstance({
        method: 'GET',
        url: MASTER_DATA_STATUS_ENDPOINT,
        params: filterObject(params)
    });
}

export function fetchSubmissionStepsService (params) {
    return masterDataAxiosInstance({
        method: 'GET',
        url: MASTER_DATA_STEPS_ENDPOINT,
        params: filterObject(params)
    });
}

export function fetchSubmissionTypesService (params) {
    return masterDataAxiosInstance({
        method: 'GET',
        url: MASTER_DATA_SUBMISSION_ENDPOINT,
        params: filterObject(params)
    });
}

export function fetchSubmissionPossibleStagesService (params) {
    return masterDataAxiosInstance({
        method: 'GET',
        url: MASTER_DATA_POSSIBLE_STAGES_ENDPOINT,
        params: filterObject(params)
    });
}
