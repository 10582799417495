import { DOWNLOAD_CANCELLED_STATUS } from '@/constants/downloads';
import { fetchActiveDowloadListService, fetchActiveDowloadJobByIdService, createActiveDownloadJobsService } from '@/api/requests/uploadRequests';

export default {
    namespaced: true,

    state () {
        return {
            downloads: null,
            activeDownloadList: null
        };
    },

    getters: {
        getDownloads (state) {
            return state.downloads;
        },
        getActiveDownloadList (state) {
            return state.activeDownloadList;
        }
    },

    mutations: {
        addDownloads (state, newDownloads) {
            if (!state.downloads) {
                state.downloads = [];
            }
            if (Array.isArray(newDownloads)) {
                state.downloads = state.downloads.concat(newDownloads);
            } else {
                state.downloads.push(newDownloads);
            }
        },
        removeDownload (state, index) {
            state.downloads.splice(index, 1);
        },
        setDownloads (state, data) {
            state.downloads = data;
        },
        cancelDownload (state, index) {
            state.downloads[index].cancelTokenSource.cancel();
            state.downloads[index].progress = 0;
            state.downloads[index].status = DOWNLOAD_CANCELLED_STATUS;
        },
        updateDownload (state, { updatedDownloadItem, index }) {
            state.downloads.splice(index, 1, updatedDownloadItem);
        },
        updateDownloadStatus (state, { downloadId, status }) {
            const matchedDownload = state.downloads.find(item => item.downloadId === downloadId);
            if (matchedDownload) {
                matchedDownload.status = status;
            }
        },
        setActiveDowloadList (state, activeDownloadList) {
            state.activeDownloadList = activeDownloadList;
        },
        setActiveDowloadJobById (state, job) {
            let idx = state.activeDownloadList.findIndex(e => e.jobId ===job.jobId)
            if (idx>=0) state.activeDownloadList.splice(idx, 1, job);
        }
    },

    actions: {
        addNewDownloadsToList ({ commit }, payload) {
            if (payload) {
                commit('addDownloads', payload);
            }
        },
        removeDownloadFromList ({ commit, state }, { itemIndex }) {
            if (state.downloads && state.downloads.length) {
                commit('removeDownload', itemIndex);
            }
        },
        cancelDownload ({ commit, state }, { index }) {
            if (state.downloads && state.downloads.length) {
                commit('cancelDownload', index);
            }
        },
        updateDownloadInList ({ commit, state }, { updatedDownloadItem, index }) {
            if (state.downloads && state.downloads.length) {
                commit('updateDownload', { updatedDownloadItem, index });
            }
        },
        updateDownloadStatusInList ({ commit, state }, { downloadId, status }) {
            if (state.downloads && state.downloads.length) {
                commit('updateDownloadStatus', { downloadId, status });
            }
        },
        async fetchActiveDowloadList ({ commit }) {
            const { data: { data } } = await fetchActiveDowloadListService();
            commit('setActiveDowloadList', data);
        },
        async fetchActiveDowloadJobById ({ commit }, id) {
            const { data: { data } } = await fetchActiveDowloadJobByIdService(id);
            commit('setActiveDowloadJobById', data);
        },
        async createActiveDownloadJobs ({ commit }, { body }) {
            await createActiveDownloadJobsService(body);
        },
        clearDownloadsList ({ commit }) {
            commit('setDownloads', null);
        }
    }
};
