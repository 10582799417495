<template>
  <router-view />
  <base-toast
    v-model="notificationsStack"
  />
</template>

<script>
import BaseToast from '@/components/generic-components/BaseToast.vue';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { onMounted } from "vue";
import useUploadStatusPopUp from './components/brand-assurance-submission-form/uploadStatusPopUp';

export default {
    name: 'App',

    components: {
        BaseToast
    },

    setup () {
        const { notificationsStack } = useToastNotifications();
        const { totalUploadProgress } = useUploadStatusPopUp();

        onMounted(() => {
            window.onbeforeunload = function (theEvent) {
                if (totalUploadProgress.value && totalUploadProgress.value < 100) {
                    theEvent.preventDefault();
                    theEvent.returnValue = '';
                }
            };
        });

        return {
            notificationsStack
        };
    }
};
</script>
